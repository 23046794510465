















import { defineComponent, PropType, reactive } from "@vue/composition-api";
import { delay } from "@/ts/system/delay";
import { SelectorSequenceExercise } from "@/ts/domain/exercises/SelectorSequenceExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import SyllablesSelector from "@/components/exercises/SyllablesSelector.vue";

/**
 * Implements logic for SelectorSequenceExercise.ts
 * See /ts/domain/exercises/SelectorSequenceExercise.ts
 *
 * The component renders all children elements as SyllablesSelector and shows one after another
 * When user clicks on a SyllablesSelector's card, the component emits exercise-action to the parent
 * And then parent must decide what to do next
 *
 * For example, m p s l => a e u o
 */
export default defineComponent({
  components: {
    SyllablesSelector
  },

  props: {
    exercise: {
      type: Object as PropType<SelectorSequenceExercise>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    props.exercise.onItemFinished.add(async () => {
      state.hideCurrentSelector = true;
      await delay(200);
    });
    props.exercise.onItemStarted.add(async () => {
      state.hideCurrentSelector = false;
    });
    props.exercise.onFinished.add(async () => {
      state.hideCurrentSelector = true;
      emit("exercise-finished");
    });

    const state = reactive({
      hideCurrentSelector: false
    });

    function playSound(): Promise<any> {
      return props.exercise.currentItem.playSound();
    }

    function startExercise(): void {
      props.exercise.start(props.exerciseFilter);
    }

    function repeatExercise(): void {
      state.hideCurrentSelector = false;
      props.exercise.repeat(props.exerciseFilter);
    }

    function repeatCurrentItem(): void {
      state.hideCurrentSelector = false;
      props.exercise.currentItem.repeat();
    }

    async function continueExercise() {
      // Let animations finish
      props.exercise.continue();
    }

    return {
      state,

      playSound,
      startExercise,
      continueExercise,
      repeatExercise,
      repeatCurrentItem
    };
  }
});
