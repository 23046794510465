









import { defineComponent, PropType, reactive } from "@vue/composition-api";

import SyllablesConcatenatorSequence from "@/components/exercises/SyllablesConcatenatorSequence.vue";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { Lesson } from "@/ts/domain/Lesson";

export default defineComponent({
  components: {
    SyllablesConcatenatorSequence
  },

  props: {
    lesson: {
      type: Object as PropType<Lesson>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    }
  },

  setup(props, { emit }) {
    const state = reactive({});

    function startLesson(): void {
      const lesson = props.lesson;
      const filter = props.exerciseFilter;

      if (lesson) {
        lesson.start(filter);
      }
    }

    return {
      state,

      startLesson
    };
  }
});
