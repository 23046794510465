import { computed, ref, Ref, ComputedRef, WritableComputedRef } from "@vue/composition-api";
import { Route } from "vue-router";

import CONSTANTS from "@/constants/index";

import { Module } from "@/ts/domain/Module";
import { useBlendingStore } from "@/store/useBlendingStore";
import { useRoute } from "@/ts/composition/vue-router";
import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";
import { useLessonsSearch } from "@/ts/composition/useLessonsSearch";
import { Segment } from "@/ts/domain/Segment";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { IStore } from "@/ts/vue/IStore";
import { LoggerService } from "@/ts/system/logger/LoggerService";
import { LessonIndex } from "@/ts/domain/LessonIndex";
import { BaseLesson } from "@/ts/domain/BaseLesson";

let state: ILessonStateStore;

/*
 * Composition should be called forceInit one time on every page load
 * It should be done inside of setup function on the root component/view (like views/blending/Lesson.vue)
 * Otherwise this composition will have issues with tracking route changes inside of computed props/re-evaluating computed props
 * I don't know why this happens, may be it's a limitation of Vue reactivity system
 *
 * Steps to reproduce:
 * 0. Remove !forceInit condition
 * 1. Go to repasso lesson (like blending/1/2/6) finish it with wrong answers
 * 2. Re-take lessons with wrong answers
 * 3. The system returns you to the page from #1
 * 4. This page loaded incorrectly because lesson computed is not re-evaluated
 */
export function useLessonStateStore(forceInit: boolean = false): ILessonStateStore {
  if (state && !forceInit) {
    return state;
  }

  const route = useRoute();
  const blendingStore = useBlendingStore();
  const search = useLessonsSearch();

  const module = computed(() => {
    return search.findModule(
      blendingStore.data.value as Module[],
      Number(route.value.params.module)
    );
  });

  const segment = computed(() => {
    return search.findSegment(module.value, Number(route.value.params.segment));
  });

  const lesson = computed(() => {
    return search.findLesson(segment.value, Number(route.value.params.lesson));
  });

  const dynamicExerciseFilter: WritableComputedRef<LessonExerciseFilter | undefined> = computed({
    get() {
      const json = localStorage.getItem(CONSTANTS.EXERCISE_FILTER_KEY);
      return json ? (JSON.parse(json) as LessonExerciseFilter) : undefined;
    },
    set(value) {
      if (value) {
        localStorage.setItem(CONSTANTS.EXERCISE_FILTER_KEY, JSON.stringify(value));
      } else {
        localStorage.removeItem(CONSTANTS.EXERCISE_FILTER_KEY);
      }
    }
  });

  const exerciseFilter: WritableComputedRef<LessonExerciseFilter | undefined> = computed({
    get() {
      if (!lesson.value) {
        return undefined;
      }

      let config: LessonExerciseFilter | undefined = undefined;

      if (lesson.value.useExerciseFilter && dynamicExerciseFilter.value) {
        config = dynamicExerciseFilter.value;
      }

      return config;
    },
    set(value) {
      dynamicExerciseFilter.value = value;
    }
  });

  const lessonExercises: ComputedRef<BaseExercise[]> = computed(() => {
    if (!lesson.value) {
      LoggerService.debug("No lesson exercises.", "useLessonStateStore");
      return [];
    }

    let result;
    const config = exerciseFilter;

    if (config.value && config.value.keys.length) {
      result = search.filterExercises(lesson.value.exercises, config.value);
    } else {
      result = lesson.value.exercises;
    }

    LoggerService.debug("Lesson exercises:", "useLessonStateStore");
    LoggerService.debug(result, "useLessonStateStore");

    return result;
  });

  function findNextLesson(): LessonIndex {
    return search.findNextLesson(module.value, segment.value, lesson.value);
  }

  state = ({
    data: blendingStore.data,
    isLoading: blendingStore.isLoading,

    module,
    segment,
    lesson,
    route,

    lessonExercises,
    exerciseFilter,

    load: blendingStore.load,
    findNextLesson
  } as unknown) as ILessonStateStore;

  return state;
}

export interface ILessonStateStore extends IStore<Module[]> {
  module: ComputedRef<Module | undefined>;
  segment: ComputedRef<Segment | undefined>;
  lesson: ComputedRef<BaseLesson | undefined>;
  route: Ref<Route>;

  lessonExercises: ComputedRef<BaseExercise[]>;
  exerciseFilter: Ref<LessonExerciseFilter | undefined>;

  load(): Promise<any>;
  findNextLesson(): LessonIndex;
}
