

















import { defineComponent, PropType, reactive, onUnmounted } from "@vue/composition-api";

import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { SelectorExerciseOption } from "@/ts/domain/exercises/SelectorExerciseOption";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import Syllable from "@/components/exercises/Syllable.vue";
import { delay } from "@/ts/system/delay";

/**
 * Implements logic for SelectorExercise.ts
 * See /ts/domain/exercises/SelectorExercise.ts
 *
 * The component takes exercise.options and finds the correct ones by filtering by SelectorExerciseOption.isCorrect field
 * Then it renders all available options on the web page and lets user to choose one
 * When user chooses one card (clicks on it), exercise emits exercise-action with 2 parameters:
 * 1. actionType: ExerciseActionEnum with 2 possible values – ExerciseActionEnum.correctOption and ExerciseActionEnum.incorrectOption
 * 2. value: SelectorExerciseOption – chosen card
 * Then the parent component must decide what to do next based on actionType – restart, continue or finish
 */
export default defineComponent({
  components: {
    Syllable
  },

  props: {
    exercise: {
      type: Object as PropType<SelectorExercise>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    props.exercise.onFinished.add(async () => {
      emit("exercise-finished");
    });

    props.exercise.onItemStarted.add(
      async () => {
        state.isClicksDisabled = true;
      },
      false,
      0
    );

    props.exercise.onItemFinished.add(
      async () => {
        state.isClicksDisabled = false;
      },
      false,
      0
    );

    // index = 0 to push animations to the beginning of action chain
    props.exercise.onIncorrectItemSelected.add(handleIncorrectItemSelection, false, 0);

    props.exercise.onCorrectItemSelected.add(handleCorrectItemSelection, false, 0);

    const state = reactive({
      isClicksDisabled: true
    });

    async function handleCorrectItemSelection(
      selector: SelectorExercise,
      played: SelectorExerciseOption,
      selected: SelectorExerciseOption
    ) {
      state.isClicksDisabled = true;

      selected.highlight = "success";
      await props.exercise.playIncentiveSound();
      selected.highlight = "";
      return;
    }

    async function handleIncorrectItemSelection(
      selector: SelectorExercise,
      played: SelectorExerciseOption,
      selected: SelectorExerciseOption
    ) {
      state.isClicksDisabled = true;

      played.highlight = "success";
      selected.highlight = "fail";
      await delay(1000);

      played.highlight = "";
      selected.highlight = "";
      return;
    }

    function playSound(): Promise<any> {
      return props.exercise.currentItem.playSound();
    }

    function startExercise(): void {
      props.exercise.start(props.exerciseFilter);
    }

    function repeatExercise(): void {
      props.exercise.repeat(props.exerciseFilter);
    }

    function continueExercise(): void {
      props.exercise.continue();
    }

    function repeatCurrentItem(): void {
      props.exercise.startAtIndex(props.exercise.currentIndex);
    }

    function _onChildClick(selectedValue: SelectorExerciseOption): void {
      props.exercise.checkAnswer(selectedValue);
    }

    // Maybe problem with rerender, but it subscribing to same events when we return to lesson;
    // Upd: problem was with state of exercise/lesson in domain part
    //! unsubscribing to the events on unmounted - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish

    // Comment from GridLesson delegate
    // We may need to remove such actions in cases when student runs the same lesson multiple times
    // Otherwise, the same code will run multiple times
    function unsubscribe() {
      props.exercise.onCorrectItemSelected.remove(handleCorrectItemSelection);
      props.exercise.onIncorrectItemSelected.remove(handleIncorrectItemSelection);
    }

    onUnmounted(unsubscribe);

    return {
      state,

      playSound,
      startExercise,
      continueExercise,
      repeatExercise,
      repeatCurrentItem,

      _onChildClick
    };
  }
});
