
































import { computed, defineComponent, PropType, reactive, ref, Ref } from "@vue/composition-api";

import { SelectorSequenceLesson } from "@/ts/domain/SelectorSequenceLesson";

import { IExercise } from "@/ts/vue/exercises/IExercise";

import SyllablesSelectorSequence from "@/components/exercises/SyllablesSelectorSequence.vue";
import Syllable from "@/components/exercises/Syllable.vue";
import SyllableTemplate from "@/components/exercises/SyllableTemplate.vue";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

/*
  Implements lesson with a bunch of SyllablesSelectorSequence.vue
  User supposed to select right syllables sequentially
  For example,
  (m) p s l => (a) e u o => ma =>
  m (p) s l => (a) e u o => pa =>
  etc
  I.e. Blending => Module 1 => Lesson 4
 */
export default defineComponent({
  components: {
    Syllable,
    SyllableTemplate,
    SyllablesSelectorSequence
  },

  props: {
    lesson: {
      type: Object as PropType<SelectorSequenceLesson>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    }
  },

  setup(props, { emit }) {
    const syllablesSelectorRefs: Ref<IExercise[]> = ref([]);

    const currentExercise = computed(() => {
      const l = props.lesson;
      return l.isFinished ? l.exercises[l.exercises.length - 1] : l.currentExercise;
    });

    const state = reactive({
      currentExercise: currentExercise,
      isExerciseFinished: false
    });

    function startLesson(): void {
      if (props.lesson) {
        props.lesson.onFinished.add(async () => {
          props.lesson.clearExerciseAnswers();
        });

        props.lesson.start(props.exerciseFilter);
      }
    }

    return {
      syllablesSelectorRefs,

      state,

      startLesson
    };
  }
});
