import { Module } from "@/ts/domain/Module";
import { Segment } from "@/ts/domain/Segment";
import { LessonIndex } from "@/ts/domain/LessonIndex";
import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { BaseLesson } from "@/ts/domain/BaseLesson";

export function useLessonsSearch() {
  function findModule(data: Module[], moduleSequenceNumber: number): Module | undefined {
    return data ? data.filter(e => e.sequenceNumber === moduleSequenceNumber)[0] : undefined;
  }

  function findSegment(
    module: Module | undefined,
    segmentSequenceNumber: number
  ): Segment | undefined {
    return module
      ? module.segments.filter(e => e.sequenceNumber === segmentSequenceNumber)[0]
      : undefined;
  }

  function findLesson(
    segment: Segment | undefined,
    lessonSequenceNumber: number
  ): BaseLesson | undefined {
    if (segment) {
      const collection = segment.lessons;
      return collection.filter(e => e.sequenceNumber === lessonSequenceNumber)[0];
    } else {
      return undefined;
    }
  }

  function findNextLesson(
    activeModule: Module | undefined,
    activeSegment: Segment | undefined,
    activeLesson: BaseLesson | undefined
  ): LessonIndex {
    if (!activeModule || !activeSegment || !activeLesson) {
      return new LessonIndex(0, 0, 0, false);
    }

    const index: LessonIndex = new LessonIndex(
      activeModule.sequenceNumber,
      activeSegment.sequenceNumber,
      activeLesson.sequenceNumber + 1,
      true
    );

    const lesson = findLesson(activeSegment, index.lesson);

    // If the next lesson is not found in the current segment then the segment is finished
    if (!lesson) {
      // Trying to find a new segment
      index.segment++;
      index.lesson = 1;
      const segment = findSegment(activeModule, index.segment);

      // If the next segment exists, go to the first lesson
      // Otherwise (next segment is not found in the current module), the module is finished
      if (!segment) {
        index.found = false;
      }
    }

    return index;
  }

  function filterExercises<T extends BaseExercise>(
    exercises: T[],
    config: LessonExerciseFilter | undefined
  ): T[] {
    if (config && config.keys && config.keys.length) {
      return exercises.filter(e => isExerciseAvailable(e, config.keys));
    } else {
      return exercises;
    }
  }

  function isExerciseAvailable(exercise: BaseExercise, activeKeys: string[]): boolean {
    return !activeKeys || !activeKeys.length || activeKeys.some(k => k === exercise.text);
  }

  return {
    findModule,
    findSegment,
    findLesson,
    findNextLesson,

    filterExercises,
    isExerciseAvailable
  };
}
