




























import { defineComponent, ref, Ref, PropType, computed } from "@vue/composition-api";

import { ISyllable } from "@/ts/vue/ISyllable";
import { GridExercise } from "@/ts/domain/exercises/GridExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import Syllable from "@/components/exercises/Syllable.vue";
import { GridExerciseSyllable } from "@/ts/domain/exercises/GridExerciseSyllable";

/**
 * Implements logic for GridExercise.ts
 * See /ts/domain/exercises/GridExercise.ts
 *
 * The component shows all exercise.children in a grid and plays children' sounds one after another
 * If children doesn't have a sound then it is shown as disabled and the component just skips it
 */
export default defineComponent({
  components: {
    Syllable
  },

  props: {
    exercise: {
      type: Object as PropType<GridExercise>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rowHeight: {
      type: Number,
      required: true
    },
    hideInactiveChildren: {
      type: Boolean,
      default: false
    },
    exerciseGridStructure: {
      type: Array as PropType<number[]>,
      required: true
    }
  },

  setup(props, { emit }) {
    const syllablesRef: Ref<ISyllable[]> = ref([]);

    const containerHeight = computed(() => {
      let result = undefined;
      const visibleSyllables = syllablesRef.value.filter(e => !e.isHidden);

      if (visibleSyllables && visibleSyllables.length) {
        // We need to use prop(first item of first gridSyllable) because it could be more than one SyllableGrids and height of second one is always 0(hidden element);
        const columns = 4;
        const rows = Math.floor(visibleSyllables.length / columns);

        result = rows * props.rowHeight;

        // Count with prop(will be uncommented when we change approach to animations )
        // result = props.exerciseGridStructure.length * props.rowHeight;
      }

      return result;
    });

    const exerciseGridRows = computed(() => {
      const rowsArray = [] as GridExerciseSyllable[][];

      let rowIndex = 0;
      let columnIndex = 0;

      props.exercise.children.forEach(item => {
        if (!rowsArray[rowIndex]) {
          rowsArray.push([]);
        }

        rowsArray[rowIndex][columnIndex] = item;

        columnIndex = columnIndex + 1;

        if (
          columnIndex >= props.exerciseGridStructure[rowIndex % props.exerciseGridStructure.length]
        ) {
          columnIndex = 0;
          rowIndex++;
        }
      });

      return rowsArray;
    });

    props.exercise.onItemStarted.add(async (exercise: GridExerciseSyllable) => {
      exercise.highlight = "success";
    });
    props.exercise.onItemFinished.add(async (exercise: GridExerciseSyllable) => {
      exercise.highlight = "";
    });
    props.exercise.onFinished.add(async () => {
      emit("exercise-finished");
    });

    function startExercise(): void {
      props.exercise.start(props.exerciseFilter);
    }

    function repeatExercise(): void {
      props.exercise.repeat(props.exerciseFilter);
    }

    return {
      syllablesRef,

      exerciseGridRows,
      containerHeight,

      startExercise,
      repeatExercise
    };
  }
});
