<template>
  <v-container fluid class="page-header d-flex align-center">
    <v-row>
      <v-col class="col-12 col-md-8 pa-0">
        <p class="font-weight-bold font-family-andika text py-4 ml-md-10 ml-lg-15 mb-0">
          {{ text }}
        </p>
      </v-col>
      <v-col class="right-side col-12 col-md-4 pa-0 d-flex align-center justify-center">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script type="ts">
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {},

  props: {
    text: String
  },

  setup() {}
});
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

.page-header {
  background-color: var(--v-primary-base);
  height: 7.5rem;
  position: relative;
  color: #fff;

  & .text {
    font-size: 2.25rem;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .page-header {
    height: auto;

    & .text {
      text-align: center;
      font-size: 1.75rem;
      line-height: 2rem;
    }
  }
}
</style>
