<template>
  <div class="progress_bar">
    <template v-for="index in total">
      <div v-if="index < current" :key="index" class="completed progress_bar-item"></div>
      <div v-else-if="index === current" :key="index" class="current progress_bar-item"></div>
      <div v-else :key="index" class="progress_bar-item"></div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {},

  props: {
    total: Number,
    current: Number
  },

  setup() {}
});
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/styles.sass";

// TODO: export variables to scss folder
$white-900: #ffffff;
$orange-400: #ffb232;

.progress_bar {
  padding-left: 28px; // the same as Circle width

  // Line
  &-item {
    display: inline-block;
    width: 80px;
    height: 10px;
    position: relative;
    background-color: $orange-400;
    margin-left: -1px;

    // Hide last dot trail
    &:first-child {
      width: 1px;
    }

    // Circles and dots inside of circles
    &::before,
    &::after {
      content: "";
      border-radius: 50%;
      position: absolute;
      right: 0;
      background-color: $orange-400;
      z-index: 1;
    }

    // Circles
    &::before {
      width: 28px;
      height: 28px;
      bottom: -9px; // Formula: negative of (circle height - line height) / 2 (for example, (20 - 5) / 2 = 7.5 => -7.5)
    }

    // Dots inside of circles
    &::after {
      width: 14px;
      height: 14px;
      bottom: -2px; // Formula: circle bottom + (circle height - dot height) / 2 (for example, -7.5 + (20 - 12) / 2 = -3.5
      right: 7px; // Formula: (circle height - dot height) / 2
    }

    &.completed {
      background-color: $orange-400;

      &::before,
      &::after {
        background-color: $orange-400;
      }
    }

    &.current {
      background-color: $orange-400;

      &::before {
        background-color: $orange-400;
      }

      &::after {
        background-color: $white-900;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .progress_bar {
    padding-left: 20px; // the same as Circle width

    &-item {
      width: 60px;
      height: 8px;

      &::before {
        width: 20px;
        height: 20px;
        bottom: -6px; // Formula: negative of (circle height - line height) / 2 (for example, (20 - 5) / 2 = 7.5 => -7.5)
      }

      // Dots inside of circles
      &::after {
        width: 10px;
        height: 10px;
        bottom: -1px; // Formula: circle bottom + (circle height - dot height) / 2 (for example, -7.5 + (20 - 12) / 2 = -3.5
        right: 5px; // Formula: (circle height - dot height) / 2
      }
    }
  }
}
</style>
