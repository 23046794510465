


















import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  onUnmounted
} from "@vue/composition-api";

import { delay } from "@/ts/system/delay";

import SyllablesGrid from "@/components/exercises/SyllablesGrid.vue";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { GridLesson } from "@/ts/domain/GridLesson";

/*
  Implements lesson with SyllablesGrid.vue
  I.e. Blending => Module 1 => Lesson 1/2
 */
export default defineComponent({
  components: {
    SyllablesGrid
  },

  props: {
    lesson: {
      type: Object as PropType<GridLesson>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    }
  },

  setup(props) {
    const syllablesGridLessonRef = ref();

    // Height of single row in SyllableGrid;
    const rowHeight = ref<number>(86);

    const currentExercise = computed(() => {
      const l = props.lesson;
      if (l) {
        return l.isFinished ? l.active[l.active.length - 1] : l.currentExercise;
      }
    });

    const state = reactive({
      currentExercise: currentExercise,
      isExerciseHidden: false
    });

    async function startLesson(): Promise<void> {
      const lesson = props.lesson;
      const filter = props.exerciseFilter;

      if (lesson) {
        // We may need to remove such actions in cases when student runs the same lesson multiple times
        // Otherwise, the same code will run multiple times
        lesson.onBeforeExerciseStarted.add(addDelayBeforeExerciseStart);
        lesson.onExerciseFinished.add(toggleCurrentExerciseVisibility);

        lesson.start(filter);
      }
    }

    // Hide current exercise only if there is another exercise to play
    async function toggleCurrentExerciseVisibility() {
      if (props.lesson.active[props.lesson.currentIndex + 1]) {
        state.isExerciseHidden = true;
        await delay(200);
        state.isExerciseHidden = false;
      }
    }

    // Add small delay before playing 1+ chart
    async function addDelayBeforeExerciseStart() {
      if (props.lesson.currentIndex > 0) {
        await delay(700);
      }
    }

    // Comment out when will fix rowHeight bug;
    // function countRowHeight() {
    //   // We take height of first syllable of the first syllables grid;
    //   rowHeight.value = syllablesGridLessonRef.value.$children[0].$el.offsetHeight;
    // }

    // Maybe problem with rerender, but it subscribing to same events when we return to lesson;
    // Upd: problem was with state of exercise/lesson is in the domain part
    //! unsubscribing to the events on unmounted - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish
    function unsubscribe() {
      props.lesson.onBeforeExerciseStarted.remove(addDelayBeforeExerciseStart);
      props.lesson.onExerciseFinished.remove(toggleCurrentExerciseVisibility);

      props.lesson.active = [];
    }

    // onMounted(countRowHeight);
    onUnmounted(unsubscribe);

    return {
      syllablesGridLessonRef,

      rowHeight,

      state,

      startLesson
    };
  }
});
